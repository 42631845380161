<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class="d-flex flex-row align-center">
          <v-btn @click="$router.go(-1)" text><v-icon>mdi-arrow-left</v-icon></v-btn>
          <h1>Logs</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3>Filters</h3>
          <span class="d-flex flex-row">
            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="filters.startDate"
                    label="Start Date"
                    readonly
                    clearable
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                />
              </template>
              <v-date-picker
                  v-model="filters.startDate"
              />
            </v-menu>
            <v-btn small class="mt-1 mx-1" color="warning" @click="filters.endDate=filters.startDate"><v-icon small>mdi-content-copy</v-icon><v-icon small>mdi-arrow-right</v-icon></v-btn>
            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="filters.endDate"
                    label="End Date"
                    readonly
                    clearable
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                />
              </template>
              <v-date-picker
                  v-model="filters.endDate"
              />
            </v-menu>
            <v-spacer/>
            <v-spacer/>
          </span>
          <span style="margin-top: -20px;" class="d-flex flex-row mb-5">
            <v-btn small class="mr-2" color="error" @click="filters.startDate=null;filters.endDate=null;">Clear Dates</v-btn>
            <v-btn small class="mr-2" color="info" @click="dailyRecords">Today</v-btn>
            <v-btn small class="mr-2" color="info" @click="monthlyRecords">This Month</v-btn>
            <v-btn small class="mr-2" color="info" @click="yearlyRecords">This Year</v-btn>
          </span>

          <span class="d-flex flex-row">
            <v-autocomplete
                class="mr-1 mb-1"
                :disabled="filters.excludedUserIds.length>0"
                v-model="filters.userIds"
                :items="getUserCache"
                :item-text="item=>(item.firstName||'')+' '+(item.lastName||'')"
                item-value="id"
                outlined
                dense
                multiple
                label="Include Users"
                clearable
                hide-details
            ></v-autocomplete>
            <v-btn small :disabled="!filters.excludedUserIds && !filters.userIds" class="mt-1" color="warning" @click="swapUserFields"><v-icon>mdi-swap-horizontal</v-icon></v-btn>
            <v-autocomplete
                class="mx-1 mb-1"
                :disabled="filters.userIds.length>0"
                v-model="filters.excludedUserIds"
                :items="getUserCache"
                multiple
                :item-text="item=>(item.firstName||'')+' '+(item.lastName||'')"
                item-value="id"
                outlined
                dense
                label="Exclude Users"
                clearable
                hide-details
            ></v-autocomplete>
          </span>

          <span class="d-flex flex-row justify-end">
            <v-btn small class="mr-2" color="info" @click="openFindProductDialog">Select Product <v-icon>mdi-magnify</v-icon></v-btn>
<!--            <v-btn small class="mr-2" color="info" @click="openFindOrderDialog">Select Order <v-icon>mdi-magnify</v-icon></v-btn>-->
          </span>

          <span class="d-flex flex-row">
            <v-autocomplete
                class="mr-2"
                v-model="filters.action"
                :items="actions"
                multiple
                outlined
                dense
                label="Actions"
                clearable
            ></v-autocomplete>
            <v-autocomplete
                class="mr-2"
                v-model="filters.model"
                :items="models"
                outlined
                multiple
                dense
                label="Model"
                clearable
            ></v-autocomplete>
            <v-text-field dense :disabled="!filters.model || filters.model.length===0" hint="A Model must be chosen first. Some Models don't support an ID" persistent-hint class="mr-2" label="ID of Data" clearable outlined v-model="filters.instanceId"/>
          </span>
          <span>
            <v-text-field label="Limit Results" outlined dense hide-details v-model="filters.limit"/>
          </span>
          <span class="d-flex flex-row">
            <span class="d-flex flex-column flex-fill pa-1">
              <v-btn color="primary" class="mb-1" @click="filters.changedKeys.push('')">+ ChangedKeys Field</v-btn>
              <span class="d-flex flex-row align-center" v-for="(item, i) of filters.changedKeys" :key="'changedKey'+i">
                <v-text-field hide-details outlined dense :label="`Key ${i+1}`" v-model="filters.changedKeys[i]"/>
                <v-btn x-small fab color="error" @click="filters.changedKeys.splice(i, 1)"><v-icon>mdi-close</v-icon></v-btn>
              </span>
            </span>
            <span class="d-flex flex-column flex-fill pa-1">
              <v-btn color="primary" class="mb-1" @click="filters.changedData.push({name: '', new: '', old: ''})">+ ChangedData Field</v-btn>
              <span class="d-flex flex-row align-center" v-for="(item, i) of filters.changedData" :key="'changedData'+i">
                <v-text-field hide-details outlined dense v-model="filters.changedData[i].name" :label="`Key ${i+1}`"/>
                <v-text-field hide-details outlined dense v-model="filters.changedData[i].old" :label="`Old Value ${i+1}`"/>
                <v-text-field hide-details outlined dense v-model="filters.changedData[i].new" :label="`New Value ${i+1}`"/>
                <v-btn x-small fab color="error" @click="filters.changedData.splice(i, 1)"><v-icon>mdi-close</v-icon></v-btn>
              </span>
            </span>
            <span class="d-flex flex-column flex-fill pa-1">
              <v-btn color="primary" class="mb-1" @click="filters.data.push({name: '', value: ''})">+ Data Field</v-btn>
              <span class="d-flex flex-row align-center" v-for="(item, i) of filters.data" :key="'data'+i">
                <v-text-field hide-details outlined dense v-model="filters.data[i].name" :label="`Key ${i+1}`"/>
                <v-text-field hide-details outlined dense v-model="filters.data[i].value" :label="`Value ${i+1}`"/>
                <v-btn x-small fab color="error" @click="filters.data.splice(i, 1)"><v-icon>mdi-close</v-icon></v-btn>
              </span>
            </span>
          </span>
          <span>
            <v-btn :loading="loader" color="success" @click="fetchRecords"><v-icon>mdi-magnify</v-icon> Search</v-btn>
          </span>
          <span class="d-flex flex-row">
            <v-switch class="mr-4" v-model="show.changed" label="Changed Data"/>
            <v-switch class="mr-4" v-if="isAllowed('logs', 'showAllData')" v-model="show.data" label="All Data"/>
            <v-switch class="mr-4" v-if="isAllowed('logs', 'showConnectionDetails')" v-model="show.connections" label="Connection Details"/>
            <v-switch class="mr-4" v-if="isAllowed('logs', 'showMetadata')" v-model="show.metadata" label="Metadata"/>
          </span>
        </v-col>
      </v-row>
      <v-row v-if="data && data.length===0">
        <v-col>
          <span class="red--text">No records found with the provided filters.</span>
        </v-col>
      </v-row>
      <v-row v-if="data">
        <v-col>
          <span class="d-flex flex-column">
            <span v-if="!filters.limit" class="font-weight-bold">{{`Found ${totalCount} records matching your filters.`}}</span>
            <span v-else class="font-weight-bold">{{`Showing ${filters.limit>totalCount?totalCount:filters.limit} of ${totalCount} records matching your filters.`}}</span>
          </span>
          <v-card class="mb-2" outlined v-for="(record, i) of data" :key="i">
            <v-card-text class="d-flex flex-column justify-space-between">
              <span class="d-flex flex-row justify-space-between">
                <span>
                  <v-chip small class="mr-2" color="success">{{getAction(record.action)}}</v-chip>
                  <v-chip small class="mr-2" color="info">{{record.model}}</v-chip>
                  <v-chip small class="mr-2" color="info" v-if="record.instanceId">ID: {{record.instanceId}}</v-chip>
                  <v-chip small class="mr-2" color="" v-if="isAllowed('logs', 'showRoute')">{{record.route}}</v-chip>
                  <span v-if="record.action==='update'">Values Changed: {{record.changedKeys.join(", ")}}</span>
                </span>
                <span>
                  <span class="px-2" style="font-size: 14px; font-style: normal;">{{lookupUsername(record.userId)||'ERROR: NO USER'}}</span>
                  <span>{{utils.formatDate(record.createdAt, "withTime")}}</span>
                </span>
              </span>
              <span class="d-flex flex-column">
                <v-card outlined class="mt-1 pa-3" v-if="show.changed">
                  <h2>Changed Data</h2>
                  <span class="d-flex flex-column" v-if="record.changedData">
                    <span v-for="(field, f) of (Object.keys(record.changedData))" :key="f">
                      <span class="font-weight-bold">{{field}}: </span>
                      <span>{{record.changedData[field].old}} <v-icon>mdi-arrow-right</v-icon> {{record.changedData[field].new}}</span>
                    </span>
                  </span>
                </v-card>
                <v-card outlined class="mt-1 pa-3" v-if="isAllowed('logs', 'showAllData') && show.data">
                  <h2>All Data</h2>
                  <span class="d-flex flex-column" v-if="record.data">
                    <span v-for="(field, f) of (Object.keys(record.data))" :key="f">
                      <span class="font-weight-bold">{{field}}: </span>
                      <span>{{record.data[field]}}</span>
                    </span>
                  </span>
                </v-card>
                <v-card outlined class="mt-1 pa-3" v-if="isAllowed('logs', 'showConnectionDetails') && show.connections">
                  <h2>Connection Details</h2>
                  <span class="d-flex flex-column">
                    <span><span class="font-weight-bold">IPV4 1: </span>{{record.ipv4x1}}</span>
                    <span><span class="font-weight-bold">IPV4 2: </span>{{record.ipv4x2}}</span>
                    <span><span class="font-weight-bold">IPV6: </span>{{record.ipv6}}</span>
                  </span>
                </v-card>
                <v-card outlined class="mt-1 pa-3" v-if="isAllowed('logs', 'showMetadata') && show.metadata">
                  <h2>Metadata</h2>
                  <pre>{{JSON.stringify(record.metadata, null, 2)}}</pre>
                </v-card>
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog scrollable v-model="findProductDialog.isOpen" width="800">
      <v-card>
        <v-card-title>Lookup Product</v-card-title>
        <v-card-text>
          <span class="d-flex flex-row justify-space-between pt-2">
            <v-text-field outlined v-model="findProductDialog.search" label="Search" @change="findProduct"/>
            <v-btn class="mt-2 ml-2" fab small color="info" @click="findProduct" :disabled="!findProductDialog.search"><v-icon>mdi-magnify</v-icon></v-btn>
          </span>
          <div style="max-height: 500px; overflow-y: scroll">
            <v-card style="cursor: pointer;" v-for="(product, i) of findProductDialog.results" :key="i" outlined class="mb-1 pa-1" @click="selectProduct(product)">
              <span class="ml-2">ID:{{product.id}} | </span>
              <span class="ml-2" style="font-size: 18px;"><b>{{product.Brand?'['+product.Brand.name+']':''}} {{product.name}}</b></span>
              <span v-if="product.sku"> | {{product.sku}}</span>
              <span style="float: right;">{{utils.formatCurrency(product.regularPrice)}}</span>
            </v-card>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn class="error" @click="closeFindProductDialog" text>Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="findOrderDialog.isOpen" width="800">
      <v-card>
        <v-card-title>Lookup Order</v-card-title>
        <v-card-text>
          <span class="d-flex flex-row justify-space-between pt-2">
            <v-text-field outlined v-model="findOrderDialog.search" label="Search" @change="findOrder"/>
            <v-btn class="mt-2 ml-2" fab small color="info" @click="findOrder" :disabled="!findOrderDialog.search"><v-icon>mdi-magnify</v-icon></v-btn>
          </span>
          <div style="max-height: 500px; overflow-y: scroll">
            <v-card style="cursor: pointer;" v-for="(order, i) of findOrderDialog.results" :key="i" outlined class="mb-1 pa-1" @click="selectOrder(order)">
              <span class="ml-2">ID:{{order.id}} | </span>
              <span style="float: right;">{{utils.formatCurrency(order.metadata?.grandTotal)}}</span>
            </v-card>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn class="error" @click="closeFindOrderDialog" text>Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
  table{
    border-collapse: collapse;
  }
  tr{
    border-bottom: 1px solid #ccc;
  }
  .CodeMirrorHeightAuto *{
    height: auto;
  }
</style>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  import utils from "../../plugins/helpers"
  import moment from "moment/moment";
  export default {
    data () {
      return {
        utils: utils,
        deleteDialog: false,
        deleteConfirmed: true,
        loader: false,
        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        filters: {
          startDate: null,
          endDate: null,
          userIds: [],
          excludedUserIds: [],
          model: [],
          action: [],
          instanceId: null,
          changedKeys: [],
          changedData: [],
          data: [],
          limit: 50
        },

        findProductDialog: {
          isOpen: false,
          search: "",
          loading: false,
          results: []
        },

        findOrderDialog: {
          isOpen: false,
          search: "",
          loading: false,
          results: []
        },

        show: {
          metadata: false,
          connections: false,
          data: true,
          changed: true
        },

        models: [],
        actions: [{text: "Create", value: "create"}, {text: "Update", value: "update"}, {text: "Delete", value: "destroy"}, {text: "Restore", value: "restore"}],

        data: null,
        totalCount: 0
      }
    },
    async mounted(){
      await this.getAllData();
    },
    computed: {
      ...mapGetters(['getEndpoint', "isAllowed", 'lookupUsername', 'getUserCache', 'getGlobalValue', 'paymentTypes'])
    },
    methods: {
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      getAction(action){
        let a = this.actions.find(x => x.value===action);
        return a?.text || ""
      },
      async getAllData(){
        try {
          this.loader = true;

          let res = await axios.get(`${this.getEndpoint}/api/logs/metadata`)
          if(res.data.error) throw res.data.error

          this.models = res.data.data.models;

          let now = new Date();
          let firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
          let lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
          let userIds = [];
          let excludedUserIds = [];
          let models = [];
          let actions = [];
          let instanceId = null;
          let changedKeys = [];
          // let changedData = [];
          // let data = []
          let limit = 50;


          if(this.$route.query.startDate){
            firstDay = new Date(this.$route.query.startDate);
          }
          if(this.$route.query.endDate){
            lastDay = new Date(this.$route.query.endDate);
          }
          if(this.$route.query.userIds){
            userIds = Array.isArray(this.$route.query.userIds)?(this.$route.query.userIds.map(x => parseInt(x))):[parseInt(this.$route.query.userIds)];
          }
          if(this.$route.query.excludedUserIds){
            if(userIds.length===0){
              excludedUserIds = Array.isArray(this.$route.query.excludedUserIds)?(this.$route.query.excludedUserIds.map(x => parseInt(x))):[parseInt(this.$route.query.excludedUserIds)];
            }
          }

          if(this.$route.query.model){
            models = Array.isArray(this.$route.query.model)?(this.$route.query.model):[this.$route.query.model];
          }
          if(this.$route.query.action){
            actions = Array.isArray(this.$route.query.action)?(this.$route.query.action):[this.$route.query.action];
          }
          if(this.$route.query.changedKeys){
            changedKeys = Array.isArray(this.$route.query.changedKeys)?(this.$route.query.changedKeys):[this.$route.query.changedKeys];
          }

          if(this.$route.query.instanceId){
            instanceId = parseInt(this.$route.query.instanceId);
          }
          if(this.$route.query.limit){
            limit = parseInt(this.$route.query.limit);
          }

          this.filters.startDate = this.utils.parseDate(firstDay);
          this.filters.endDate = this.utils.parseDate(lastDay);
          this.filters.userIds = userIds
          this.filters.excludedUserIds = excludedUserIds
          this.filters.model = models
          this.filters.action = actions
          this.filters.instanceId = instanceId
          this.filters.limit = limit
          this.filters.changedKeys = changedKeys

          this.$forceUpdate();
        } catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      },
      async fetchRecords(){
        try {
          this.loader = true;

          this.data = null;
          this.totalCount = 0;

          let res = await axios.post(`${this.getEndpoint}/api/logs`, this.filters)
          if(res.data.error) throw res.data.error

          let changed = false;
          let tempObj = {...this.filters}
          delete tempObj.product

          if(Object.keys(this.$route.query).length!==Object.keys(tempObj).length) changed = true;
          if(!changed){
            for(let key of Object.keys(this.$route.query)){
              if(tempObj[key]!=this.$route.query[key] && !Array.isArray(tempObj[key])){
                if((this.$route.query[key]==='true' && tempObj[key]) || (this.$route.query[key]==='false' && !tempObj[key])) continue;
                changed = true;
                break;
              }
              if(Array.isArray(tempObj[key])){
                if(Object.keys(this.$route.query[key]).length!==Object.keys(tempObj[key]).length){
                  changed = true;
                  break;
                }
                for(let i=0;i<tempObj[key].length;i++){
                  if(tempObj[key][i]!=this.$route.query[key][i]){
                    changed = true;
                    break;
                  }
                }
              }
            }
          }

          if(changed) await this.$router.replace({query: {...tempObj}});
          if(res.data.data.length===0) throw "No records found within the provided filters."

          console.log(res.data)

          this.data = res.data.data;
          this.totalCount = res.data.totalCount||0;

        } catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      },

      async swapUserFields(){
        if(this.filters.userIds.length>0){
          let temp = this.filters.userIds;
          this.filters.userIds = [];
          this.filters.excludedUserIds = temp;
        }
        else if(this.filters.excludedUserIds.length>0){
          let temp = this.filters.excludedUserIds;
          this.filters.excludedUserIds = [];
          this.filters.userIds = temp;
        }
      },

      openFindProductDialog(){
        this.findProductDialog.isOpen = true;
      },
      closeFindProductDialog(){
        this.findProductDialog = {
          loading: false,
          results: [],
          search: "",
          isOpen: false
        }
      },
      async findProduct(){
        try{
          if(!this.findProductDialog.search) return

          let searchData = {val: this.findProductDialog.search}
          let uriFields = Object.keys(searchData).map(x => {
            return x + "=" + (searchData[x]!==undefined?encodeURIComponent(searchData[x]):'')
          }).join("&");

          let res = await axios.get(`${this.getEndpoint}/api/products/searchByAllVariationsNoLimit?${uriFields}`);
          if(res.data.error) throw res.data.error
          this.findProductDialog.results = res.data.data;

        } catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.findProductDialog.loading = false;
        }
      },
      selectProduct(product){
        if(!this.filters.model){
          this.filters.model = ['Product'];
          this.filters.instanceId = product.id;
        }
        else if(this.filters.model && !this.filters.model.includes('Product')){
          this.filters.model.push('Product');
          this.filters.instanceId = product.id;
        }
      },

      openFindOrderDialog(){
        this.findOrderDialog.isOpen = true;
      },
      closeFindOrderDialog(){
        this.findOrderDialog = {
          loading: false,
          results: [],
          search: "",
          isOpen: false
        }
      },
      async findOrder(){
        try{
          if(!this.findOrderDialog.search) return

          let searchData = {val: this.findOrderDialog.search}
          let uriFields = Object.keys(searchData).map(x => {
            return x + "=" + (searchData[x]!==undefined?encodeURIComponent(searchData[x]):'')
          }).join("&");

          let res = await axios.get(`${this.getEndpoint}/api/orders/liteData?${uriFields}`);
          if(res.data.error) throw res.data.error
          this.findOrderDialog.results = res.data.data;

        } catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.findOrderDialog.loading = false;
        }
      },

      async dailyRecords(){
        this.filters.startDate = moment().format("YYYY-MM-DD");
        this.filters.endDate = moment().format("YYYY-MM-DD");
      },
      async monthlyRecords(){
        this.filters.startDate = moment().startOf('month').format("YYYY-MM-DD");
        this.filters.endDate = moment().endOf('month').format("YYYY-MM-DD");
      },
      async yearlyRecords(){
        this.filters.startDate = moment().startOf('year').format("YYYY-MM-DD");
        this.filters.endDate = moment().endOf('year').format("YYYY-MM-DD");
      },
    }
  }
</script>
